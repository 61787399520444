/**
 * Created by anatoly on 28.08.2024
 */

var DangerAnimationView = cc.Node.extend({
    ctor: function (component) {
        this._super();

        this.component = component;

        this.bg = new cc.Sprite(bundles.danger_component.frames.bg_room);
        this.bg.setPositionRound(cleverapps.styles.DangerAnimation.bg);

        this.addChild(this.bg, -1);

        this.setContentSize(this.bg.getContentSize());
        this.setAnchorPoint(0.5, 0.5);

        this.updateSize();

        this.addHero();
        this.addDrillAnimation();

        this.setupChildren();

        component.on("onStart", this.startAnimation.bind(this));
        component.on("onStop", this.stopDrill.bind(this));
    },

    addDrillAnimation: function () {
        var styles = cleverapps.styles.DangerAnimation.drills;

        this.drillWall = new cc.Sprite(bundles.danger_component.frames.block);

        this.drillWall.setPositionRound(this.width - this.drillWall.width / 2, this.drillWall.height / 2);

        this.addChild(this.drillWall);

        this.drills = [];

        for (var i = 0; i < DangerAnimationView.DRILLS_COUNT; i++) {
            var drill = new cleverapps.Spine(bundles.danger_component.jsons.drill);
            drill.setAnimation(0, "idle", true);

            this.drills.push(drill);
        }

        this.drillsLayout = new cleverapps.Layout(this.drills, {
            margin: styles.margin
        });
        this.drillsLayout.setPositionRound(styles);

        this.drillWall.addChild(this.drillsLayout);
    },

    addHero: function () {
        var styles = cleverapps.styles.DangerAnimation.hero;

        var person = cleverapps.persons.getRole(styles.personCode);
        var hero = this.hero = new cleverapps.Spine(person.json);
        hero.setAnimation(0, "idle_tricky", true);

        this.component.on("onNoMuchTime", function () {
            hero.setAnimation(0, "scared", true);
        });

        hero.setPositionRound(styles);

        this.addChild(hero, -1);
    },

    startAnimation: function () {
        this.hero.setAnimationAndIdleAfter("start", "idle_tricky");
        this.startDrillAnimation();

        this.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.CallFunc(function () {
                    this.drillSound = cleverapps.audio.playSound(bundles.danger_component.urls.tricky_drill);
                }.bind(this)),
                new cc.DelayTime(8)
            )
        ));
    },

    setDrillsStopAnimation: function () {
        this.drills.forEach(function (drill) {
            drill.setAnimation(0, "stop");
        });

        cleverapps.audio.playSound(bundles.danger_component.urls.tricky_drill_win);
    },

    getDrillEndXPosition: function () {
        var hero = this.hero;

        return hero.getPosition().x
            + hero.width / 2 * hero.scale
            + (this.drillWall.width + this.drillsLayout.width) / 2 * this.drillWall.scale
            - this.drillsLayout.getPosition().x * this.drillWall.scale;
    },

    startDrillAnimation: function () {
        if (this.component.stopped) {
            return;
        }

        var timeLeft = this.component.getTimeLeft();

        this.drillWall.runAction(new cc.MoveTo(timeLeft / 1000, this.getDrillEndXPosition(), this.drillWall.y));
    },

    onWin: function () {
        this.setDrillsStopAnimation();
        this.hero.setAnimation(0, "joy", true);

        this.hero.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.danger_component.urls.tricky_applause),
            new cc.DelayTime(2),
            new cc.CallFunc(function () {
                this.removeHero();
            }.bind(this))
        ));
    },

    onLose: function () {
        this.hero.setAnimation(0, "disappear", false);
        this.hero.runAction(new cc.Sequence(
            new cc.DelayTime(2),
            new cc.CallFunc(function () {
                this.removeHero();
            }.bind(this))
        ));
    },

    removeHero: function () {
        this.hero.runAction(new cc.Sequence(
            new cc.MoveTo(0.4, cc.p((-this.hero.width * this.hero.scale) / 2, this.hero.y)).easing(cc.easeIn(2)),
            new cc.RemoveSelf()
        ));
    },

    getPartFromScreen: function () {
        return cleverapps.styles.DangerAnimation.partFromScreen[cleverapps.resolution.mode];
    },

    updateSize: function () {
        var rect = cleverapps.resolution.getBgRect();
        var partFromScreen = this.getPartFromScreen();

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            var totalWidth = rect.width * partFromScreen;
            this.setScale(Math.min(totalWidth / this.width, rect.height / this.height));
        } else {
            var totalHeight = rect.height * partFromScreen;
            this.setScale(Math.min(totalHeight / this.height, rect.width / this.width));
        }
    },

    setupChildren: function () {
        var styles = cleverapps.styles.DangerAnimation;

        var rect = cleverapps.resolution.getSceneSize();

        var position;
        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            position = cc.p(rect.width * this.getPartFromScreen() / 2, rect.height / 2);
        } else {
            position = cc.p(rect.width / 2, rect.height * (1 - this.getPartFromScreen()) + rect.height * this.getPartFromScreen() / 2);
        }

        var offsets = styles.offsets[cleverapps.resolution.mode];
        this.setPosition(cc.pAdd(position, offsets));

        this.hero.setScale(styles.hero.scale);
    },

    hide: function (f) {
        var scale = this.getScale();

        this.runAction(
            new cc.ScaleTo(0.3, scale * 0.5).easing(cc.easeBackIn()).setFinalize(function () {
                this.setVisible(false);
                f();
            }.bind(this))
        );
    },

    stopDrill: function () {
        this.stopAllActions();
        this.drillWall.stopAllActions();

        cleverapps.audio.stopSound(this.drillSound);
    }
});

DangerAnimationView.DRILLS_COUNT = 3;

cleverapps.styles.DangerAnimation = {
    partFromScreen: [0.4, 0.4, 0.55],

    bg: {
        x: { align: "center" },
        y: { align: "center" }
    },

    originalSize: {
        width: 1080,
        height: 768
    },

    offsets: [
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 0, y: -60 }
    ],

    positions: [
        {
            x: { align: "center" },
            y: { align: "top" }
        },
        {
            x: { align: "center" },
            y: { align: "top" }
        },
        {
            x: { align: "left" },
            y: { align: "center" }
        }
    ],

    hero: {
        x: { align: "left", dx: 100 },
        y: { align: "bottom", dy: 110 },

        scale: 0.8,
        personCode: "yellow"
    },

    drills: {
        x: { align: "center", dx: -105 },
        y: { align: "center", dy: 25 },

        margin: 40
    }
};