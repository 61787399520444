/**
 * Created by anatoly on 18.10.2024
 */

var DangerWarningAnimation = cc.Scale9Sprite.extend({
    ctor: function (type, component) {
        this._super(bundles.danger_component.frames.warning);

        this.type = type;

        this.setOpacity(0);

        this.updateSize();
        this.setupChildren();

        component.on("onNoMuchTime", this.startWarningAnimation.bind(this));
        component.on("onStop", this.stopAnimation.bind(this));
    },

    setupChildren: function () {
        var styles = cleverapps.styles.DangerWarningAnimation;

        var x = styles[this.type].x;
        var y = styles.y;

        if (this.type === DangerWarningAnimation.TYPES.RIGHT) {
            this.setScaleX(-1);
        }

        this.setPositionRound(x, y, { box: cleverapps.resolution.getBgRect() });
    },

    updateSize: function () {
        var bgRect = cleverapps.resolution.getBgRect();
        this.setContentSize(this.width, bgRect.height);
    },

    startWarningAnimation: function () {
        this.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.FadeIn(0.8),
                new cc.FadeOut(0.8)
            )
        ));

        this.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.CallFunc(function () {
                    this.sound = cleverapps.audio.playSound(bundles.danger_component.urls.tricky_siren);
                }),
                new cc.DelayTime(1.6)
            )
        ));
    },

    stopAnimation: function () {
        this.stopAllActions();
        this.runAction(new cc.FadeOut(0.4));

        cleverapps.audio.stopSound(this.sound);
    }
});

DangerWarningAnimation.TYPES = {
    RIGHT: "right",
    LEFT: "left"
};

cleverapps.styles.DangerWarningAnimation = {
    y: { align: "center" },

    left: {
        x: { align: "left" }
    },

    right: {
        x: { align: "right" }
    }
};